<template>
  <v-row class="d-flex flex-row fill-height">
    <v-col class="fill-height" md="3" sm="12">
      <v-card class="pa-1 shadow fill-height">
        <v-card-title>
          <v-btn color="primary" block outlined @click="addChapter">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-subheader>章节</v-subheader>
        <v-card-text>
          <v-list subheader v-resize="onResize" :height="clientHeight" style="overflow-y: scroll;">
            <v-list-item-group v-model="selectedChapter">
              <template v-for="(chapter, index) in chapters">
                <v-list-item
                  :key="`chapter-${index}`"
                  @click="toChapter(index)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-folder</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="d-flex align-center">
                    <v-list-item-title v-if="chapter.id" class="subtitle-1">
                      {{ chapter.title }}
                    </v-list-item-title>
                    <v-edit-dialog v-else>
                      <v-list-item-title class="subtitle-1 mt-1">{{
                        chapter.title
                      }}</v-list-item-title>
                      <template v-slot:input>
                        <v-text-field
                          v-model="chapter.title"
                          solo
                          flat
                          dense
                          hide-details
                          single-line
                        />
                      </template>
                    </v-edit-dialog>
                  </v-list-item-content>
                  <v-list-item-action v-if="!chapter.id">
                    <v-btn
                      icon
                      color="primary"
                      class="rounded caption"
                      @click="saveChapter(index)"
                    >
                      保存
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col class="fill-height" md="9" sm="12">
      <v-card class="pa-1 fill-height" flat>
        <router-view />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "",
  data() {
    return {
      selectedChapter: null,
      isNewChapter: false
    };
  },

  computed: {
    ...mapGetters("chapter", {
      chapters: "getList",
      currentNewChapter: "getCurrentChapter"
    }),
    clientHeight: {
      set(val) {
        this.$store.dispatch("common/setClientHeight", val);
      },
      get() {
        return this.$store.getters["common/getClientHeight"];
      }
    }
  },

  created() {
    this.setList();
  },

  watch: {
    chapters() {
      this.setCurrentRoute();
    }
  },

  methods: {
    onResize() {
      this.clientHeight = document.documentElement.clientHeight;
    },

    setCurrentRoute() {
      const { id } = this.$route.query;

      if (id) {
        const res = this.chapters.find(item => {
          if (item.id === parseInt(id)) {
            return item;
          }
        });

        this.selectedChapter = this.chapters.indexOf(res);
      } else {
        this.selectedChapter = this.chapters.length - 1;
      }
    },

    addChapter() {
      const chapter = { title: `第${this.chapters.length + 1}章` };
      this.selectedChapter = this.chapters.length;

      this.chapters.push(chapter);
    },

    toChapter(index) {
      const id = this.chapters[index].id;
      this.selectedChapter = index;

      if (!id) {
        this.isNewChapter = true;
        this.$router
          .push({
            path: "/project/single/list",
            query: { isNew: 0, type: "add" }
          })
          .catch(() => {});
      } else {
        this.isNewChapter = false;
        this.$router
          .push({
            path: "/project/single/list",
            query: { isNew: 1, type: "edit", id }
          })
          .catch(() => {});
      }
    },

    saveChapter(index) {
      // this.chapters[index].id = this.chapters.length + 1;
      const newChapter = this.chapters[index];
      this.createChapter(newChapter).then(() => this.setList());
    },

    ...mapActions("chapter", ["setList", "createChapter"])
  }
};
</script>

<style scoped lang="scss">
.qrcode-preview,
.qrcode-button {
  width: 150px;
  height: 150px !important;
}
</style>
